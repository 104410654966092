<template>
  <div class="unit-type-details">
    <div class="container fluid">
      <unit-type-detail
        type="agency"
        :canAdd="canAdd"
        :canEdit="canEdit"
        :canDelete="canDelete"
      ></unit-type-detail>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    UnitTypeDetail: () =>
      import("@/modules/Project/components/UnitType/UnitTypeDetail")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      canAdd: false,
      canEdit: false,
      canDelete: false
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getAccessPermission();
    },
    async getAccessPermission() {
      this.canAdd = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "project.create"
      );
      this.canEdit = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "project.update"
      );
      this.canDelete = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "project.delete"
      );
    }
  }
};
</script>

<style lang="scss"></style>
